import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import icon1 from "assets/img/icon/icon1.png";
import icon1_active from "assets/img/icon/icon1_active.png";
import icon2 from "assets/img/icon/icon2.png";
import icon2_active from "assets/img/icon/icon2_active.png";
import icon3 from "assets/img/icon/icon3.png";
import icon3_active from "assets/img/icon/icon3_active.png";
import icon4 from "assets/img/icon/icon4.png";
import icon4_active from "assets/img/icon/icon4_active.png";
import icon5 from "assets/img/icon/icon5.png";
import icon5_active from "assets/img/icon/icon5_active.png";
import { useStores } from "_common/hooks";

export default function MobileFooter({ toggleMenu, toggleCollapsed, menus }) {
  const {
    authStore: { logo },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  const [openPayment, setOpenPayment] = useState(false);

  return (
    <div className="footer-mobile">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        {pathName === "/" ? (
          <img src={icon1_active} alt="Trang chủ" />
        ) : (
          <img src={icon1} alt="Trang chủ" />
        )}
        <h5>Trang chủ</h5>
      </Link>
      <Link
        to="/beauty"
        className={classNames("footer_item", {
          active: pathName === "/beauty",
        })}
      >
        {pathName === "/beauty" ? (
          <img src={icon2_active} alt="Nước ngoài" />
        ) : (
          <img src={icon2} alt="Nước ngoài" />
        )}
        <h5>Nước ngoài</h5>
      </Link>
      <Link
        to="/game"
        className={classNames("footer_item", {
          active: pathName === "/game",
        })}
      >
        {pathName === "/game" ? (
          <img src={icon3_active} alt="Hoàn thiện" />
        ) : (
          <img src={icon3} alt="Hoàn thiện" />
        )}
        <h5>Hoàn thiện</h5>
      </Link>
      <Link
        to="/movie"
        className={classNames("footer_item", {
          active: pathName === "/movie",
        })}
      >
        {pathName === "/movie" ? (
          <img src={icon4_active} alt="Công ty" />
        ) : (
          <img src={icon4} alt="Công ty" />
        )}
        <h5>Công ty</h5>
      </Link>
      <Link
        to="/member"
        className={classNames("footer_item", {
          active: pathName === "/member",
        })}
      >
        {pathName === "/member" ? (
          <img src={icon5_active} alt="Của tôi" />
        ) : (
          <img src={icon5} alt="Của tôi" />
        )}
        <h5>Hồ sơ</h5>
      </Link>
    </div>
  );
}
