import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { MovieApi } from "states/api/movie";
import { GirlApi } from "states/api/girl";

export default function ListRoom() {
  const navigate = useNavigate();

  const { data, refetch } = useQuery(
    ["movie", "list_home"],
    () =>
      GirlApi.getList({
        params: {
          page: 1,
          limit: 20,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  return (
    <div className="hot-recommend">
      <div className="list-video">
        {data?.data?.map((item) => (
          <div className="item" key={item?.id}>
            <img alt="" src={item?.image_url} />
            <span className="vip">VIP S</span>
            <div className="info">
              <p className="title">{item?.code}</p>
              <p className="salary">{item?.salary}</p>
              <p className="location">
                <FontAwesomeIcon icon={faClock} />
                <span>Hôm nay</span>
                <FontAwesomeIcon icon={faLocationPin} />
                <span>Quận Cầu Giấy</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
