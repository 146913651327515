import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import NotifyProvider from "_common/component/NotifyProvider";
import MobileLayout from "./MobileLayout";

const UnAuthBasicLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.logo) return <PageLoading />;

  return (
    <NotifyProvider user_id={authStore?.user?.id}>
      <MobileLayout />
    </NotifyProvider>
  );
});

export default UnAuthBasicLayout;
