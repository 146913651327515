import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMoneyBillTransfer,
  faWallet,
  faRotate,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import walletIcon from "assets/icons/wallet.svg";
import gameIcon from "assets/icons/game.svg";
import agencyIcon from "assets/icons/agency.svg";
import noticeIcon from "assets/icons/notice.svg";
import movieIcon from "assets/icons/movie.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div className="header_member">
        <div className="member-mask" />
        <div className="van-nav-bar">
          <div className="van-nav-bar__right">
            <FontAwesomeIcon
              icon={faCog}
              onClick={() => navigate("/member/setting")}
            />
          </div>
        </div>
        <div className="user-wrapper">
          <div className="user-avatar">
            <img src={user?.avatar_url} alt="" />
          </div>
          <div className="user-content">
            <h3 className="name">{user?.username}</h3>
            {/* <div className="level">
              <span>VIP{user?.level}</span>
            </div> */}
          </div>
        </div>
        <div className="finance">
          <Link to="/payment/deposit" className="finance-item">
            <FontAwesomeIcon icon={faMoneyBillTransfer} />
            <p>Số dư</p>
          </Link>
          <Link to="/payment/withdraw" className="finance-item">
            <FontAwesomeIcon icon={faWallet} />
            <p>Rút điểm</p>
          </Link>
        </div>
      </div>
      <div className="member-container">
        <div className="wallet">
          <div className="wallet-title">
            <p>Ví của tôi</p>
            <span onClick={() => navigate("/histories/account")}>
              Chi tiết
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
          <div className="wallet-balance">
            <h5 className="balance">{formatNumber(user_wallet?.amount)}</h5>
            <div className="action">
              <button
                type="button"
                className="btn refresh-btn"
                onClick={() => getProfile()}
              >
                <FontAwesomeIcon icon={faRotate} />
              </button>
            </div>
          </div>
        </div>
        <div className="member-menu">
          <Link to="/histories/game" className="menu-item">
            <img src={gameIcon} alt="" />
            <div className="info">
              <h5>Lịch Sử Đăng Ký</h5>
            </div>
          </Link>
          <Link to="/member/information" className="menu-item">
            <img src={agencyIcon} alt="" />
            <div className="info">
              <h5>Hồ sơ nhân Viên</h5>
            </div>
          </Link>
          <Link to="/about" className="menu-item">
            <img src={noticeIcon} alt="" />
            <div className="info">
              <h5>Giới thiệu</h5>
            </div>
          </Link>

          <Link to="/cskh" className="menu-item">
            <img src={movieIcon} alt="" />
            <div className="info">
              <h5>Dịch Vụ Trực Tuyến</h5>
            </div>
          </Link>
        </div>
        {/* <div className="member-footer">
          <button
            type="button"
            className="btn btn-logout"
            onClick={() => onLogout()}
          >
            Đăng xuất
          </button>
        </div> */}
      </div>
    </>
  );
});

export default MemberPage;
