import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    username: yup.string().required("Bắt buộc điền"),
    password: yup.string().required("Bắt buộc điền"),
  })
  .required();

export default function LoginComponent() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleLogin)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="username"
                type="text"
                placeholder="Vui lòng nhập tên đăng nhập"
                {...register("username")}
              />
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>

          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <InputPassword
                className="field_control"
                id="password"
                placeholder="Vui lòng nhập mật khẩu "
                {...register("password")}
              />
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
        </ul>
        <div className="forgot-pass">
          <span>Quên mật khẩu</span>
        </div>
        <p className="signin-other-title">
          Bạn chưa có tài khoản? <Link to="/auth/register">Đăng ký ngay</Link>
        </p>
        <div className="form_button">
          <input type="submit" className="btn btn-submit" value="Đăng nhập" />
        </div>
      </div>
    </form>
  );
}
