import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { useNotify } from "_common/component/NotifyProvider";

const DepositForm = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const { pushNotify } = useNotify();
  const [amount, setAmount] = useState("");

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          token_id: 3,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        navigate(`/payment/deposit/success?money=${amount}`);
        pushNotify({
          type: "deposit",
        });
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số điểm lớn hơn ${formatNumber(general?.min_deposit)}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số điểm nhỏ hơn ${formatNumber(general?.min_deposit)}`
      );
    onSubmit();
    return null;
  };

  return (
    <div className="payMoney">
      <div className="label">
        <span>Vui lòng nhập số tiền nạp</span>
      </div>
      <div className="money">
        <input
          inputMode="numeric"
          pattern="[0-9]"
          placeholder="Nhập số điểm"
          className="control-number"
          value={amount ? formatNumber(amount) : ""}
          onChange={handleChangeInput}
        />
        <div className="group-control">
          <span>Kiểu</span>
          <select>
            <option>Ngân hàng trực tuyến</option>
            <option>MOMO</option>
            <option>QR</option>
            <option>ZALO</option>
          </select>
        </div>
      </div>
      <button
        type="button"
        className={classNames("btn btn-sub", {
          active: Number(amount || 0) >= Number(general?.min_deposit || 0),
        })}
        onClick={handleSubmit}
      >
        Số dư
      </button>
    </div>
  );
});

export default DepositForm;
