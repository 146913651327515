import React from "react";
import { Link, useNavigate } from "react-router-dom";

// redux
import { useStores } from "_common/hooks";

// component
import LoginComponent from "./LoginForm";

function LoginPage() {
  const navigate = useNavigate();
  const {
    authStore: { logo, general },
  } = useStores();

  return (
    <div className="remix-auth-page">
      <div className="bg-wrapper">
        <div className="auth-container">
          <h2 className="title">{general?.name_website}</h2>

          <LoginComponent />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
