import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import b1 from "assets/img/video/b1.jpg";
import b2 from "assets/img/video/b2.jpg";
import b3 from "assets/img/video/b3.jpg";
import b4 from "assets/img/video/b4.jpg";
import b5 from "assets/img/video/b6.jpg";
import b6 from "assets/img/video/b7.jpg";

const MoviePage = observer(() => {
  const [active_tab, setActiveTab] = useState("new");
  return (
    <div className="movie-hall page">
      <h1>GIẤY PHÉP KINH DOANH VÀ DỊCH VỤ CỦA THE VN GROUP</h1>
      <div className="mowtainer">
        <div className="mowgrid">
          <div className="member">
            <div className="image">
              <img src={b1} alt="" />
            </div>
          </div>
          <div className="member">
            <div className="image">
              <img src={b2} alt="" />
            </div>
          </div>
          <div className="member">
            <div className="image">
              <img src={b3} alt="" />
            </div>
          </div>
          <div className="member">
            <div className="image">
              <img src={b4} alt="" />
            </div>
          </div>
        </div>
        <div className="image" style={{ marginTop: 40 }}>
          <img src={b5} alt="" />
        </div>
        <div className="image" style={{ marginTop: 40 }}>
          <img src={b6} alt="" />
        </div>
      </div>
    </div>
  );
});

export default MoviePage;
