import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import DepositForm from "./DepositForm";

const DepositPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  return (
    <>
      <div className="header_member header_payment">
        <HeaderTitle
          title="Số dư"
          subTitle={
            <span onClick={() => navigate(`/histories/deposit`)}>
              hồ sơ nạp điểm
            </span>
          }
        />
        <div className="user-wrapper">
          <div className="user-avatar">
            <img src={user?.avatar_url} alt="" />
          </div>
          <div className="user-info">
            <h3 className="username">{user?.username}</h3>
            <h3 className="balance">
              Số dư: {formatNumber(user_wallet?.amount)}
            </h3>
          </div>
        </div>
      </div>
      <div className="payment-container">
        <DepositForm />
      </div>
    </>
  );
});

export default DepositPage;
