import React, { useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import NotifyProvider from "_common/component/NotifyProvider";

const GameLayout = observer(() => {
  const { authStore } = useStores();
  const [params] = useSearchParams();

  const level = params.get("code");

  const gameDetail = useMemo(() => {
    if (!level) return {};
    return authStore.game_hot.find((obj) => obj?.room_id === level);
  }, [authStore?.game_hot, level]);

  if (!authStore.logo) return <PageLoading />;
  if (!authStore.token) return <PageLoading />;
  if (!authStore.user?.id) return <PageLoading />;

  return (
    <div className="game-layout">
      <NotifyProvider game={gameDetail} user_id={authStore?.user?.id}>
        <Outlet />
      </NotifyProvider>
    </div>
  );
});

export default GameLayout;
