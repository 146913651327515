import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import zaloIcon from "assets/img/icon/zalo.png";
import telegramIcon from "assets/img/icon/telegram.png";
import facebookIcon from "assets/img/icon/facebook.png";
import cskhIcon from "assets/img/icon/cskh.png";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

const SupportComponent = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  const handleOpenLink = (link) => {
    window.open(link);
  };

  return (
    <>
      <HeaderTitle title="CSKH" />
      <div className="update-info-container">
        <div className="support-content">
          {general?.link_zalo && (
            <div
              className="item"
              onClick={() => handleOpenLink(general?.link_zalo)}
            >
              <div className="left-text">
                <img src={zaloIcon} alt="" />
                <span>Zalo</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
          {general?.link_telegram && (
            <div
              className="item"
              onClick={() => handleOpenLink(general?.link_telegram)}
            >
              <div className="left-text">
                <img src={telegramIcon} alt="" />
                <span>Telegram</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
          {general?.link_facebook && (
            <div
              className="item"
              onClick={() => handleOpenLink(general?.link_facebook)}
            >
              <div className="left-text">
                <img src={facebookIcon} alt="" />
                <span>Facebook</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default SupportComponent;
