import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function HeaderHome({ isBack = true, title }) {
  const {
    authStore: { general },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top">
      <h1 className="title">{general?.name_website}</h1>
      <div className="search-input">
        <input type="text" placeholder="Tìm kiếm" className="form-control" />
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
}

export default HeaderHome;
