import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useQuery } from "@tanstack/react-query";
import { GirlApi } from "states/api/girl";

export default function HotVideo() {
  const navigate = useNavigate();
  const {
    authStore: { video_hot },
  } = useStores();

  const { data, refetch } = useQuery(
    ["movie", "list_hot"],
    () =>
      GirlApi.getList({
        params: {
          page: 1,
          limit: 6,
          is_view: 1,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  return (
    <div className="hot-recommend hot">
      <div className="slide-video">
        {data?.data?.map((item) => (
          <div className="item" key={item?.id}>
            <img alt="" src={item?.image_url} />
            <span className="hot">Nổi bật</span>
            <p className="title">{item?.code}</p>
            <p className="salary">{item?.salary}</p>
            <p className="location">
              <FontAwesomeIcon icon={faClock} />
              <span>Hôm nay</span>
              <FontAwesomeIcon icon={faLocationPin} />
              <span>Quận Cầu Giấy</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
