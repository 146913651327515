import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useNavigate, useSearchParams } from "react-router-dom";

const jobs = [
  {
    title: "Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD",
    salary: "4000 AUD",
    country: "Úc",
  },
  {
    title: "Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp",
    salary: "85 triệu ",
    country: "Australia Úc",
  },
  {
    title: "41 đơn hàng Singapore mới nhất tuyển liên tục",
    salary: "40000 ",
    country: "Singapore",
  },
  {
    title: "Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts",
    salary: "25 AUD/giờ ",
    country: "Úc",
  },
  {
    title: "Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói",
    salary: "1.100 USD ",
    country: "Ba lan",
  },
  {
    title: "Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm",
    salary: "1.100 USD ",
    country: "Ba lan",
  },
  {
    title:
      "Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm",
    salary: "2.800 Euro ",
    country: "CHLB Đức",
  },
  {
    title:
      "Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng",
    salary: "80 triệu ",
    country: "New Zealand",
  },
  {
    title: "Tuyển lao động làm xây dựng tại New Zealand",
    salary: "4000- 6,500 NZD ",
    country: "New Zealand",
  },
  {
    title: "Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand",
    salary: "5000-5500 NZ ",
    country: "New Zealand",
  },
  {
    title: "Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao",
    salary: "2.200 Euro ",
    country: "Hungary",
  },
  {
    title: "Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới",
    salary: "1.100 USD ",
    country: "Hungary",
  },
  {
    title:
      "3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử",
    salary: "2000USD ",
    country: "Hungary",
  },
];

const BeautyPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const city = params.get("city");
  const tab = params.get("tab");

  const [active_tab, setActiveTab] = useState("tab1");

  useEffect(() => {
    if (!tab) return;
    setActiveTab(tab);
  }, [tab]);

  return (
    <>
      <HeaderTitle title="Nước ngoài" isBack={false} />
      <div className="main-content convention-hall">
        <div className="page-content">
          <h5>VIỆC LÀM NHẬT BẢN MỚI NHẤT</h5>
          <ul role="tablist" className="nav nav-tabs">
            <li className="active">Tất cả đơn hàng mới</li>
            <li>Kỹ sư đi Nhật Bản</li>
            <li>Đơn hàng cho nam</li>
            <li>Đơn hàng cho nữ</li>
          </ul>
          <table className="donhang-home">
            <tbody>
              <tr>
                <td>Ngành nghề</td>
                <td>Ngày thi</td>
                <td>Giới tính</td>
                <td>Lương cơ bản</td>
                <td>Số lượng</td>
              </tr>
              <tr>
                <td>Thực phẩm</td>
                <td>15/09/2023</td>
                <td>Nữ </td>
                <td>153.054 Yên </td>
                <td>30</td>
              </tr>
              <tr>
                <td>Thực phẩm</td>
                <td>25/09/2023</td>
                <td>Nam/Nữ </td>
                <td>170.000 Yên </td>
                <td>24</td>
              </tr>
              <tr>
                <td>Công xưởng</td>
                <td>28/09/2023</td>
                <td>Nam Nữ </td>
                <td>4000 AUD </td>
                <td>100</td>
              </tr>
              <tr>
                <td>Thực phẩm</td>
                <td>18/09/2023</td>
                <td>Nữ </td>
                <td>176.000 Yên </td>
                <td>18</td>
              </tr>
              <tr>
                <td>Đóng gói</td>
                <td>16/09/2023</td>
                <td>Nữ </td>
                <td>157.000 Yên </td>
                <td>30</td>
              </tr>
              <tr>
                <td>Thủy Sản</td>
                <td>25/09/2023</td>
                <td>Nam Nữ </td>
                <td>157.000 Yên </td>
                <td>120</td>
              </tr>
              <tr>
                <td>Cơ khí</td>
                <td>24/09/2023</td>
                <td>Nam </td>
                <td>160.768 Yên </td>
                <td>54</td>
              </tr>
              <tr>
                <td>Thực phẩm</td>
                <td>15/09/2023</td>
                <td>Nữ </td>
                <td>157.000 Yên </td>
                <td>60</td>
              </tr>
              <tr>
                <td>Thực phẩm</td>
                <td>24/09/2023</td>
                <td>Nữ </td>
                <td>157.000 Yên </td>
                <td>54</td>
              </tr>
              <tr>
                <td>Phân Loại</td>
                <td>29/09/2023</td>
                <td>Nam </td>
                <td>167.385 Yên </td>
                <td>20</td>
              </tr>
            </tbody>
          </table>
          <h2 className="widget-title">
            Việc làm ngoài nước mới nhất
            <span>Xem thêm</span>
          </h2>
          <div className="jobs">
            {jobs?.map((item, index) => (
              <div className="item" key={`item-${index?.toString()}`}>
                <h3>{item?.title}</h3>
                <h4>
                  Mức lương: <span>{item?.salary}</span>
                </h4>
                <h4>
                  Quốc gia:<span>{item?.country}</span>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default BeautyPage;
