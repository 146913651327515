import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import SupportComponent from "./Support";

export default function CSKh() {
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  if (Number(general?.support_live) !== 1) return <SupportComponent />;

  return (
    <>
      <HeaderTitle title="CSKH" />
      <div className="update-info-container">
        {general?.link_support && (
          <iframe
            className="iframe-cskh"
            title="chat_live"
            src={general?.link_support}
            frameBorder="true"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </>
  );
}
