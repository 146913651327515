import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useQueryClient } from "@tanstack/react-query";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCoins } from "@fortawesome/free-solid-svg-icons";

const DepositSuccessPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();
  const [params] = useSearchParams();
  const money = params.get("money");

  const handleSubmit = () => {
    navigate("/member");
  };

  return (
    <>
      <HeaderTitle title="Yêu cầu nạp điểm thành công" />

      <div className="payment-container">
        <div className="info">
          <FontAwesomeIcon icon={faCheckCircle} />
          <div>
            <p>
              Yêu cầu nạp điểm hoàn thành, Đơn đăng ký nạp điểm của bạn đã được
              gửi thành công
            </p>
            <p className="des">Số điểm nạp: {formatNumber(money)}K</p>
          </div>
        </div>
        <div className="note">
          <FontAwesomeIcon icon={faCoins} />
          <div>
            <p className="title">Tiền đã vào tài khoản</p>
            <p>
              Sau yêu cầu nạp điểm thành công,Số dư của bạn sẽ được cập nhật
              trong vòng một phút,Vui lòng kiểm tra sau
            </p>
            <p>
              Nếu vẫn chưa được cập nhật vào thời điểm đó{" "}
              <Link to="/">Dịch vụ trực tuyến</Link>
            </p>
          </div>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub active")}
          onClick={handleSubmit}
        >
          Hoàn thành
        </button>
      </div>
    </>
  );
});

export default DepositSuccessPage;
