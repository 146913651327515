export const paymentStatusText = {
  pending: "Đang chờ xem xét",
  complete: "Hoàn thành",
  error: "Từ chối",
  reject: "Từ chối",
};

export const paymentStatusColor = {
  pending: "#ff8c1a",
  complete: "green",
  error: "red",
};

export const gameStatusText = {
  betGame: "Đang bình chọn",
  win: "Xác nhận đã bình chọn",
  loss: "Chưa xác nhận bình chọn",
};

export const gameTypeText = {
  code1: "Lâu Dài",
  code2: "Thời Vụ",
  code3: "Hành Chính",
  code4: "Tăng Ca",
};

export const gameLevelText = {
  level_1: "VOTE 1",
  level_2: "VOTE 2",
  level_3: "VOTE 3",
};
