import classNames from "classnames";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";

export default function OptionData({ data, lots, onSelect }) {
  const {
    authStore: { game_hot },
  } = useStores();

  const handleSelect = (code) => {
    const cloneData = [...lots];
    const checkExit = cloneData.findIndex((obj) => obj === code);

    if (checkExit > -1) {
      cloneData.splice(checkExit, 1);
    } else {
      cloneData.push(code);
    }

    onSelect(cloneData);
  };

  return (
    <div className="options-bar">
      <div className="right">
        <div className="twoSidesSum">
          {data?.bet_type &&
            Object.keys(data?.bet_type)?.map((key) => (
              <div
                key={key}
                className={classNames("rectangle large ", {
                  active: lots.indexOf(key) > -1,
                })}
                onClick={() => handleSelect(key)}
              >
                <div className="name-text large">{data?.bet_type[key]}</div>
                <p className="rate">2.0</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
