import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";

export default function ModalConfirm({ open, onClose }) {
  return (
    <Portal>
      <div className="modal modal-confirm" style={{ display: open && "block" }}>
        <div className="mask" onClick={onClose} />
        <div className="modal-content modal-bottom">
          <div className="content-body">
            <h4>Thông báo</h4>
            <p>
              Muốn check hàng ngon, vui lòng nâng hạn VIP để tận hưởng dịch vụ
              này.
            </p>
          </div>

          <div className="btn-group">
            <button type="button" className="btn btn-cancel" onClick={onClose}>
              Huỷ
            </button>
            <button type="button" className="btn btn-confirm" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
}
