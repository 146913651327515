import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import DepositForm from "./DepositForm";

const DepositFormPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  return (
    <>
      <HeaderTitle title="Nạp điểm" />

      <div className="payment-container payment-form-page">
        <DepositForm />
      </div>
    </>
  );
});

export default DepositFormPage;
